import { render, staticRenderFns } from "./mmleditor.vue?vue&type=template&id=61c39b70&scoped=true&lang=pug"
import script from "./mmleditor.vue?vue&type=script&lang=js"
export * from "./mmleditor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61c39b70",
  null
  
)

/* custom blocks */
import block0 from "./mmleditor.vue?vue&type=custom&index=0&blockType=router"
if (typeof block0 === 'function') block0(component)

export default component.exports