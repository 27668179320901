<router>
{
    name: 'Sales Tool Parts',
}
</router>
<template lang="pug">
v-container
    v-btn(text, color='brand', @click='goToMMLs')
        v-icon(left) arrow_back
        | Return to MML List
    v-card(light, flat)
        v-card-title Edit MML
        v-card-text
            v-col
                MMLEdit(:id='mmlId', ref='mmledit')
            v-card-text
</template>
<script>

import MMLEdit from '@/components/salestool/mmlEdit.vue';
export default {
    props: {
    },
    meta: {
        role: 'sat'
    },
    components: {
        MMLEdit,
    },
    data () {
        return {
            mmlId: ''
        };
    },
    methods: {
        goToMMLs () {
            this.$store.dispatch('mmls/leaveRoom');
            this.$router.push('/sat/parts');
        },
        resetMMLEditor () {
            const mmlComponent = this.$refs.mmledit;
            if (mmlComponent) {
                mmlComponent.reset();
            }
        }
    },
    computed: {

    },
    mounted () {
        this.mmlId = this.$route.query.id;
    },
    beforeRouteLeave (to, from, next) {
        this.resetMMLEditor();
        next();
    }
};
</script>

<style scoped>
</style>
